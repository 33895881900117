<template>
  <div class="content-wrapper">
    <TopBar />
    <div class="support-wrapper">
      <v-container class="pt-0 pb-0 d-flex justify-end">
        <v-row gutters="12">
          <v-col cols="12" md="6" lg="6">
            <img src="images/edufide-new-design/support-img.png" alt="">
          </v-col>
          <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-center justify-center">
            <h2>KNOWLEDGE BASE</h2>
            <div class="search-field">
              <input type="text">
              <img src="images/edufide-new-design/magnify.svg" alt="">
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Dashboard Banner End Here-->
    <div class="help-desk-wrapper">
      <v-container class="pt-0 pb-0">
        <h2>HELP DESK</h2>
        <v-row gutters="12">
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="card">
              <span>
                <img src="images/edufide-new-design/instructors.svg" alt="">
              </span>
              <h3>For Instructors</h3>
              <p>Setting up and managing your assignments & assessments</p>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="card">
              <span>
                <img src="images/edufide-new-design/students.svg" alt="">
              </span>
              <h3>For Students</h3>
              <p>Submitting & organizing <br> your work</p>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="card">
              <span>
                <img src="images/edufide-new-design/admin.svg" alt="">
              </span>
              <h3>For Administrators</h3>
              <p>Integrating Assign2 with Learning Management Systems</p>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="card">
              <span>
                <img src="images/edufide-new-design/faq.svg" alt="">
              </span>
              <h3>FAQs</h3>
              <p>Answers to most common <br> support questions</p>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="card">
              <span>
                <img src="images/edufide-new-design/contact.svg" alt="">
              </span>
              <h3>Contact Support</h3>
              <p>Can’t find the answer to <br> your question?</p>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="card">
              <span>
                <img src="images/edufide-new-design/privacy.svg" alt="">
              </span>
              <h3>Privacy & Accessibility</h3>
              <p>View our privacy and <br> accessibility policy</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Help Desk End Here-->
    <FooterView />
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  components: {
    TopBar,
    FooterView,
  },
  name: "Support",
  data: () => ({

  }),
};
</script>

<style scoped></style>