import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { colors } from "@/theme";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: colors.primary,
                success: colors.success,
                error: colors.error
            }
        }
    }
};

export default new Vuetify(opts);
