<template>
  <main class="min-height-calc">
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section
      id="hero"
      class="light-bg "
    >
      <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <header>
                <h1 style="font-size: 30px">Knowledge base</h1>
                <!-- <p>Magnis modipsae que voloratati andigen daepeditem quiate aut labor. Laceaque quiae
                sitiorem rest non restibusaes es dem tumquam.</p>-->
                </header>
            </div>
            <!-- /.col -->
            <div class="col-md-12">
                <!-- <div class="course-card">
                    <a href="javascript:">Moving a Course Online</a>
                    <p>
                        Article Last Updated July 2021 PLEASE NOTE: eClassLive (Adobe Connect) has been
                        decommissioned as of January 3rd 2021 [http://support.eclass.ualberta.ca/index.
                        php?/News/NewsItem/View/188/adobe-connect-eclasslive-decommissioning]. 
                        This article outlines...
                    </p>
                </div> -->
                <!-- <div class="course-card">
                    <a href="javascript:">IST eClass Services and Support Team</a>
                    <p>
                        Article Last Updated April 2021 Part of the Digital Learning Environment (DLE) portfolio,
                        the IST eClass Support team supports the applications enabling digital teaching and 
                        learning including eClass, Zoom, ePoll, ePortfolios, and a range of tools suppo...
                    </p>
                </div> -->
            </div>
            <!-- /.col -->
        </div>
        <!-- ./row -->
        <div class="row">
            <div class="col-md-6">
                <a href="javascript:" class="heading"> 
                    Assign2 for Instructors
                    <span></span>
                </a>
                <ul>
                    <li>
                        <a href="docs-create-print-exam">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Creating an in-person exam
                        </a>
                    </li>
                    <li>
                        <a href="docs-create-assignment">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Creating a student submitted assignment
                        </a>
                    </li>
                    <li>
                        <a href="docs-marking-submissions">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Marking student submissions and exams
                        </a>
                    </li>
                    <li>
                        <a href="docs-return-assessments">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Returning assessments to students
                        </a>
                    </li>
                    <li>
                        <a href="docs-manage-students">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Managing students in Assign2
                        </a>
                    </li>
                    <li>
                        <a href="docs-replace-bubblesheets">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Replacing proprietary bubblesheets with Assign2
                        </a>
                    </li>
                    <!-- <li>
                        <a href="javascript:">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            How to sign-up for free
                        </a>
                    </li> -->
                    
                </ul>
            </div>
            <!-- // -->
            <div class="col-md-6">
                <a href="javascript:" class="heading"> 
                    Assign2 for Students
                    <span></span>
                </a>
                <ul>
                    <li>
                        <a href="docs-submit-assignment">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Submitting an assignment on Assign2
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <a href="javascript:" class="heading"> 
                    Assign2 for Administrators
                    <span></span>
                </a>
                <ul>
                    <!-- <li>
                        <a href="javascript:">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Integrating Assign2 with Moodle
                        </a>
                    </li> -->
                    <li>
                        <a href="docs-integrate-canvas">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Integrating Assign2 with Canvas
                        </a>
                    </li>
                    <li>
                        <a href="docs-integrate-moodle">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Integrating Assign2 with Moodle
                        </a>
                    </li>
                    <!-- <li>
                        <a href="javascript:">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Integrating Assign2 with Brightspace
                        </a>
                    </li> -->

                    <!-- <li>
                        <a href="javascript:">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Application wide settings
                        </a>
                    </li> -->

                </ul>
            </div>    
            <div class="col-md-6">
                <a href="javascript:" class="heading"> 
                    Smart Exam Monitor V2
                    <span></span>
                </a>
                <ul>
                    <li>
                        <a href="docs-take-exam-semv2">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            How to take an exam using Smart Exam Monitor V2
                        </a>
                    </li>
                    <li>
                        <a href="docs-instructor-semv2">
                            <img src="images/art/icon_kbarticlesmall.png" alt="">
                            Smart Exam Monitor V2 - For Instructors
                        </a>
                    </li>
                </ul>
            </div>                                
        </div>
      </div>
      <!-- /.container -->
    </section>    
  </main>
</template>

<script>
export default {

  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }

};
</script>

<style scoped>

.course-card {
    text-align: left;
    border-radius: 6px;
    margin-bottom: 25px;
    background-color: #fff9e7;
    border: 1px solid #eedeae;
    padding: 10px 10px 6px 10px;
}
.course-card a {
    font-size: 18px;
    color: #067842;
    margin-bottom: 5px;
    display: inline-block;
}
.course-card a:hover {
    color: #2e75bf;
}
.course-card p {
    color: #4c5156;
    margin: 0 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
}
.heading {
    display: flex;
    font-size: 16px;
    color: #4c5156;
    font-weight: 600;
    margin: 0 0 15px 0;
    padding: 0 0 2px 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cfd4d6;
}
.heading span {
    font-size: 13px;
    color: #9fa3a8;
    letter-spacing: 1px;
    font-weight: normal;
}
ul {
    padding: 0;
    margin: 0 0 30px;
}
ul li {
    list-style: none;
    margin-top: 10px;
}
ul li a {
    display: flex;
    font-size: 14px;
    color: #2e75bf;
    line-height: 20px;
    font-weight: normal;
    align-items: center;
}
ul li a:hover {
    color: #094078;
}
ul li a img {
    margin: -2px 5px 0 0;
}
.popular-heading {
    display: flex;
    font-size: 16px;
    color: #4c5156;
    font-weight: 600;
    margin-bottom: 15px;
    align-items: center;
}
.popular-heading::after {
    flex: 1;
    width: 100%;
    content: "";
    margin-left: 10px;
    display: inline-block;
    border-top: 1px solid #d9d9d9;
}

</style>