<template>
  <div class="content-wrapper">
    <TopBar />
    <div class="products-banner">
      <v-container class="pt-0 pb-0 d-flex justify-center">
        <div class="alignment-box">
          <h1>ASSIGN2</h1>
          <p>
            Elevate your grading experience with Assign2 – the
            advanced tool that reads handwritten work, recognizes
            mathematical equivalences, and offers algorithmic grading.
            Effortlessly streamline the assessment process, save time,
            and provide detailed feedback to boost learning outcomes.
          </p>
        </div>
      </v-container>
    </div>
    <!--Dashboard Banner End Here-->
    <div class="how-two-work">
      <v-container class="pt-0 pb-0">
        <h2>HOW ASSIGN2 WORKS</h2>
        <p>
          Seamless steps from assessment creation to grading and performance analysis.
        </p>
      </v-container>
      <div class="performance-analysis">
        <v-container class="pt-0 pb-0">
          <v-row gutters="12">
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card">
                <span class="card-serial">1.</span>
                <img src="images/edufide-new-design/create.svg" alt="">
                <h3>Create & Distribute Assessments</h3>
                <p class="mb-0">
                  Create an online or paper-based assessment or assignment in
                  Assign2 with various question types.
                </p>
              </div>
            </v-col>
            <!--//-->
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card">
                <span class="card-serial red-color">2.</span>
                <img src="images/edufide-new-design/submit.svg" alt="">
                <h3>Submit & Upload Completed Work</h3>
                <p class="mb-0">
                  Students upload their work as PDF or by using their mobile
                  phone. Instructors scan paper-based work and upload
                  to match automatically to student.
                </p>
              </div>
            </v-col>
            <!--//-->
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card">
                <span class="card-serial green-color">3.</span>
                <img class="max-w-55" src="images/edufide-new-design/grade.svg" alt="">
                <h3>Grade and Provide Feedback</h3>
                <p class="mb-0">
                  Use versatile grading tools for thorough feedback, collaborate
                  with your team, and deliver grades online instantly.
                </p>
              </div>
            </v-col>
            <!--//-->
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card">
                <span class="card-serial yellow-color">4.</span>
                <img class="max-w-90" src="images/edufide-new-design/analyze.svg" alt="">
                <h3>Analyze & Enhance Learning</h3>
                <p class="mb-0">
                  Assign2 offers student performance analysis, grading speed
                  statistics, and assessment design insights for effective
                  differentiation.
                </p>
              </div>
            </v-col>
            <!--//-->
          </v-row>
        </v-container>
      </div>
    </div>
    <!--How Two Work End Here-->
    <div class="grading-feedback-wrapper">
      <v-container class="pt-0 pb-0">
        <v-row gutters="12" class="pb-15">
          <v-col cols="12" md="6" lg="6">
            <img class="feedback-image" src="images/edufide-new-design/Feedback.png" alt="">
          </v-col>
          <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-start justify-center pl-10">
            <h2>Comprehensive <br> Feedback</h2>
            <p class="paragraph-text">
              Provide feedback on each part of a student's solution, ensuring they
              understand their strengths and areas for improvement. Integrate
              PDFs, images, graphs, videos, etc. to improve student learning.
            </p>
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="12" class="pb-15 mobile-view-reverse-column">
          <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-start justify-center pr-10">
            <h2>Effortless, Adaptable <br> Grading</h2>
            <p class="paragraph-text">
              Easily provide detailed feedback with a single click using a customizable grading
              interface and keyboard. Reduce the need for proprietary bubble sheets and scanners
              by using Assign2 to automatically grade multiple choice items. Supports concurrent
              marking by multiple graders.
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <div class="image-placement"></div>
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="12" class="pb-15">
          <v-col cols="12" md="6" lg="6">
            <div class="image-placement"></div>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="d-flex flex-column align-start justify-center pl-10">
            <h2>Auto-Grading & <br> Peer Assessment</h2>
            <p class="paragraph-text">
              Enhance educational experience and assessment quality with Assign2’s
              auto-grading feature. Reads handwritten work, recognizes mathematically equivalent
              expressions, employs grading logic trees for customization, offers algorithmic grading,
              and comprehensively checks work to pro- vide a thorough assessment.
            </p>
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="12" class="pt-15">
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="name-department-institution">
              <div class="image-circle"></div>
              <p class="mb-5">
                “Lorem ipsum dolor sit amet, consec- tetuer adipiscing elit, sed diam nonum-
                my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                wisi enim ad minim veniam”
              </p>
              <h3>Name <br> Department</h3>
              <h4>Institution Name</h4>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="name-department-institution">
              <div class="image-circle"></div>
              <p class="mb-5">
                “Lorem ipsum dolor sit amet, consec- tetuer adipiscing elit, sed diam nonum-
                my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                wisi enim ad minim veniam”
              </p>
              <h3>Name <br> Department</h3>
              <h4>Institution Name</h4>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="name-department-institution">
              <div class="image-circle"></div>
              <p class="mb-5">
                “Lorem ipsum dolor sit amet, consec- tetuer adipiscing elit, sed diam nonum-
                my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                wisi enim ad minim veniam”
              </p>
              <h3>Name <br> Department</h3>
              <h4>Institution Name</h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Grading Feedback Wrapper End Here-->
    <FooterView />
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  components: {
    TopBar,
    FooterView,
  },
  name: "Products",
  data: () => ({

  }),
};
</script>

<style scoped></style>