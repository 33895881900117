<template>
  <div class="content-wrapper">
    <TopBar />
    <div class="dashboard-banner">
      <v-container class="pt-0 pb-0 d-flex justify-end">
        <div class="alignment-box">
          <h1>Introducing <span>Assign</span><span>2</span></h1>
          <p>
            Reinvents grading for superior
            learning outcomes, offering rich
            feedback for student growth.
          </p>
          <div>
            <v-btn color="primary">Watch Video</v-btn>
            <v-btn color="primary">Get a Trial</v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <!--Dashboard Banner End Here-->
    <div class="clients-wrapper">
      <v-container class="pt-0 pb-0">
        <v-row gutters="12">
          <v-col cols="12" sm="12" lg="3" class="d-flex align-center">
            <p>Some of our clients include:</p>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <img src="images/edufide-new-design/university-of-alberta.png" alt="">
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <img src="images/edufide-new-design/brand-logo-800w-1.jpeg" alt="">
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <img class="w-100" src="images/edufide-new-design/Midland_College.jpeg" alt="">
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Clients Wrapper End Here-->
    <div class="grading-designed">
      <v-container class="pt-0 pb-0">
        <v-row gutters="12" class="pb-15">
          <v-col cols="12" lg="4">
            <img class="w-100" src="images/edufide-new-design/Educators-img.svg" alt="">
          </v-col>
          <v-col cols="12" lg="8" class="d-flex flex-column align-start justify-center">
            <h2>Designed for educators by educators</h2>
            <p>
              Assign2 is a product of collaboration with educators at one of Canada's
              foremost universities. Our primary goal is to deliver the most efficient
              assessment grading tools available, and we're actively expanding our offerings
              with features like peer-assessment and automated grading.
            </p>
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="12" class="mb-15 pb-15 column-mobile-view-reverse">
          <v-col cols="12" lg="8" class="d-flex flex-column align-start justify-center pl-15">
            <h2>Grading efficiency</h2>
            <p class="mt-4">
              Streamlining the grading process, Assign2 provides an accessible interface for
              swift assessment of numerous exams. It supports the use of reusable scored
              comments and rubrics, customization via keyboard shortcuts, and easy sharing
              of rubric and comments libraries within the grading team to eliminate
              conflicts.
            </p>
          </v-col>
          <v-col cols="12" lg="4" class="position-relative">
            <img class="w-100" src="images/edufide-new-design/Grading-img.svg" alt="">
            <img class="absolute-img" src="images/edufide-new-design/Grading-img2.svg" alt="">
          </v-col>
        </v-row>
        <!--///-->
        <v-row gutters="12" class="mb-15 pb-5">
          <v-col cols="12" lg="4" class="position-relative">
            <img class="w-100" src="images/edufide-new-design/Learning-img.svg" alt="">
            <img class="img-absolute" src="images/edufide-new-design/Learning-img2.svg" alt="">
          </v-col>
          <v-col cols="12" lg="8" class="d-flex flex-column align-start justify-center pl-150">
            <h2>Better Learning Outcomes</h2>
            <p>
              Assign2 ensures graded assessments can be shared with students for
              review and feedback. It goes a step further by offering performance
              statistics and assessment quality insights. This continuous
              improvement loop results in more refined assessments and
              ultimately benefits the overall academic journey.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Grading Designed End Here-->
    <div class="meets-grading-wrapper">
      <v-container class="pt-0 pb-0">
        <h2><span>Assign</span><span>2</span> meets all your grading and assessment needs:</h2>
        <v-row gutters="12">
          <v-col cols="12" md="6" lg="6">
            <div class="students-detail">
              <img src="images/edufide-new-design/Matching.svg" alt="">
              <div class="ml-10">
                <h3>Student Matching</h3>
                <p>
                  Assign2 matches students automatically to scanned exams,
                  saving valuable grading time.
                </p>
              </div>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" md="6" lg="6">
            <div class="students-detail">
              <img src="images/edufide-new-design/Economical.svg" alt="">
              <div class="ml-10">
                <h3>Scalable and Economical</h3>
                <p>
                  Assign2 was designed for campus-wide scalability and costs a
                  fraction of our competitors.
                </p>
              </div>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" md="6" lg="6">
            <div class="students-detail">
              <img src="images/edufide-new-design/Multiple-choice.svg" alt="">
              <div class="ml-10">
                <h3>Multiple Choice Exams</h3>
                <p>
                  Assign2 scores multiple choice exams automatically, reducing the need
                  for proprietary bubble sheets and scanners.
                </p>
              </div>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" md="6" lg="6">
            <div class="students-detail">
              <img src="images/edufide-new-design/ProvenTrack.svg" alt="">
              <div class="ml-10">
                <h3>Proven Track Record</h3>
                <p>
                  Assign2 has been used to grade more than 1.5million assessment items.
                </p>
              </div>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" md="6" lg="6">
            <div class="students-detail">
              <img src="images/edufide-new-design/Integrated.svg" alt="">
              <div class="ml-10">
                <h3>Integrated and Secure</h3>
                <p>
                  Assign2 integrates with most popular Learning Management Systems over LTI.
                </p>
              </div>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" md="6" lg="6">
            <div class="students-detail">
              <img src="images/edufide-new-design/Change.svg" alt="">
              <div class="ml-10">
                <h3>Driving Change</h3>
                <p>
                  Assign2 supports innovative features such as two-stage group exams,
                  peer-assessment and auto grading.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Meets Grading Wrapper End Here-->
    <div class="learning-management">
      <v-container class="pt-0 pb-0">
        <h2>Fully integrated with learning management systems, including:</h2>
        <div class="logos-wrapper">
          <img src="images/edufide-new-design/logo-lms-canvas.png" alt="">
          <img src="images/edufide-new-design/logo-lms-brightspace.png" alt="">
          <img src="images/edufide-new-design/logo-lms-blackboard.png" alt="">
          <img src="images/edufide-new-design/logo-lms-moodle.png" alt="">
          <img src="images/edufide-new-design/logo-lms-sakai.png" alt="">
        </div>
      </v-container>
    </div>
    <!--Learning Management End Here-->
    <FooterView />
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  components: {
    TopBar,
    FooterView,
  },
  name: "Dashboard",
  data: () => ({

  }),
};
</script>

<style scoped></style>