<template>

    <section class="submit-assign-section">
        <div class="containe">
            <div class="content-area">
                <h1>Taking an exam using Smart Exam Monitor V2</h1>
                <p>
                    This article describes how to take an exam using Smart Exam Monitor V2.
                </p>

                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <li>
                            <a href="#requiredDevice">
                                <span>1</span>
                                Required Device(s)
                            </a>
                        </li>
                        <li>
                            <a href="#examSpace">
                                <span>2</span>
                                Examination Space
                            </a>
                        </li>
                        <li>
                            <a href="#browserRequirements">
                                <span>3</span>
                                Browser Requirements
                            </a>
                        </li>
                        <li>
                            <a href="#deviceValidation">
                                <span>4</span>
                                Device Validation
                            </a>
                        </li>
                        <!-- app installation -->
                        <li>
                            <a href="#appInstallation">
                                <span>5</span>
                                App Installation
                            </a>
                        </li>
                        <!-- Taking an exam -->
                        <li>
                            <a href="#takingExam">
                                <span>6</span>
                                Taking an exam
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // -->
                <div class="pt-55" id="requiredDevice">
                    <h2>Required Device(s)</h2>
                    <p>
                        To take an exam using Smart Exam Monitor V2, you will need a computer with a webcam and microphone. 
                        <br>
                        <strong>Please note that mobile devices such as cellphones and 
                        tablets are not supported.
                        </strong>
                        <br>
                        <img src="images/docs-take-exam-semv2/requireddevices.png" alt="">
                        <br>
                        <br>
                        <strong>
                            A secondary device such as a smartphone or tablet may also be required for certain exams.
                        </strong>
                    </p>
                </div>
                <!-- // -->
                <div class="pt-55" id="examSpace">
                    <h2>Examination Space</h2>
                    <p>
                        To ensure a fair examination environment, you must have a clutter-free exam space. 
                        <br>
                        <strong>
                            You must not have any books, notes, or any other materials on your desk or within reach. 
                            You must also not have any other electronic devices such as cellphones, tablets, or smartwatches 
                            within reach.
                        </strong>
                        <br>
                        Please follow the instructions provided by your instructor to ensure that your exam space is appropriate.
                    </p>

                    <img src="images/docs-take-exam-semv2/clutter.png" alt="">
                </div>
                <!-- // -->
                <div class="pt-55" id="browserRequirements">
                    <!-- latest chrome is required -->
                    <h2>Browser Requirements</h2>
                    <p>
                        Smart Exam Monitor V2 requires the latest version of Google Chrome. 
                        <br>
                        <strong>
                            Please ensure that you have the latest version of Google Chrome installed on your computer.
                        </strong>
                    </p>
                    <br>
                    <!-- https://www.google.com/chrome/ -->
                    <a href="https://www.google.com/chrome/" target="_blank">
                        <img src="images/docs-take-exam-semv2/chrome1.png" alt="">
                    </a>
                </div>
                <div class="pt-55" id="deviceValidation">
                    <h2>Device Validation</h2>
                    <p>
                        Before you can take an exam using Smart Exam Monitor V2, you must validate your device. 
                        <br>
                        This process ensures that your computer meets the requirements for taking an exam using Smart Exam Monitor V2.
                        <br>
                        The following permissions are required for the device validation process:
                        <br>
                        <br>
                        <ul>
                            <li>Camera</li>
                            <li>Microphone</li>
                            <li>Notifications</li>
                            <li>Pop-ups and redirects</li>
                            <li>Window Management</li>
                            <li>Clipboard</li>
                        </ul>
                        <br>
                        Permissions can be granted by clicking on the site information icon in the address bar of Google Chrome.
                    </p>
                    <br>
                    <img src="images/docs-take-exam-semv2/dval1.png" alt="">
                    <img src="images/docs-take-exam-semv2/dval2.png" alt="">

                </div>
                <div class="pt-55" id="appInstallation">
                    <h2>App Installation</h2>
                    <p>
                        Once you have validated your device, you will need to install the Smart Exam Monitor V2 app.
                        <br>
                        Click the "Install Smart Exam Monitor V2 App" button to install the app.
                    </p>
                    <br>
                    <img src="images/docs-take-exam-semv2/appinstall.png" alt="">
                    <img src="images/docs-take-exam-semv2/appinstall2.png" alt="">

                </div>
                <!-- // -->
                <div class="pt-55" id="takingExam">
                    <h2>Taking an exam</h2>
                    <p>
                        You are now ready to take an exam using Smart Exam Monitor V2. 
                        <br>
                        <strong>
                            Make sure the Smart Exam Monitor window is maximized and you are not using any other applications or browser tabs.
                        </strong>
                        <br>
                        <br>
                        Follow the exam setup wizard to access your exam. You will be required to share your camera and screen. Additionally, 
                        you may be required to record your exam area. Finally, you will be required to take a picture of yourself and your ID.

                        <br>
                        <br>
                        On the final step, you will be provided the exam instructions and you will be able to start the exam.

                        <br>
                        <br>
                
                        <strong>
                            Please note that you must not close the exam window or navigate away from the exam page 
                            until you have completed and submitted the exam.
                        </strong>
                    </p>
                </div>

            </div>
        </div>
    </section>


</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.ytcontainer {
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: 370px;
    padding-bottom: 0px;
}
.ytvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 360px;
}
.flex-box {
    display: flex;
    padding-top: 30px;
}
.flex-box span {
    margin-right: 5px;
    line-height: 28px;
}
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #ff0000;
}
.submit-assign-section .content-area .card {
    max-width: 300px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    margin-bottom: 0;
    line-height: 28px;
}
.submit-assign-section .content-area img {
    margin-top: 5px;
    margin-left: 20px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
}
</style>