<template>
  <div>
    <div class="top-bar-header">
      <v-container class="d-flex align-center justify-center pt-0 pb-0">
        <p>
          Interested in trying Assign2 for free?
          <a href="https://edufide.com/demo">Click here to learn more.</a>
        </p>
      </v-container>
    </div>
    <!--Top Header End Here-->
    <v-app-bar
        prominent
        class="app-bar-top"
    >
      <v-container class="d-flex align-center justify-space-between pt-0 pb-0">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon class="drawer-btn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <img class="logo" src="images/edufide_logo_linear.svg" alt="Logo">
        </div>
        <div class="d-flex align-center">
          <ul class="d-flex align-center">
            <li>
              <router-link to="">Products</router-link>
            </li>
            <li>
              <router-link to="">About</router-link>
            </li>
            <li>
              <router-link to="">Support</router-link>
            </li>
            <li>
              <router-link to="">Contact</router-link>
            </li>
          </ul>
          <v-btn class="small-size-mobile" color="primary">Get a Trial</v-btn>
        </div>
      </v-container>
    </v-app-bar>
    <!--App Bar End Here-->
    <v-navigation-drawer
        v-model="drawer"
        absolute
        top
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
  <!--Show on Mobile View-->
</template>

<script>

export default {
  data: () => ({
    location: 'top',
    drawer: null,
  }),
}

</script>

<style scoped>

.logo {
  width: 220px;
}
.v-navigation-drawer {
  top: 0 !important;
  bottom: auto !important;
}
.top-bar-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  position: fixed;
  background: #DCE3EB;
  padding: 7px 25px 6px;
}
.top-bar-header p {
  margin: 0;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}
.top-bar-header p a {
  border: 0;
  color: #000000;
  font-weight: 600;
  text-decoration: none;
}
.top-bar-header span {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-left: 30px;
}
.app-bar-top {
  top: 34px;
  z-index: 2;
  position: fixed;
  padding: 8px 0 8px;
  height: auto !important;
  background: #ffffff !important;
}
.app-bar-top:deep(.v-toolbar__content) {
  height: auto !important;
}
.drawer-btn {
  display: none;
}
.app-bar-top ul li {
  margin-right: 40px;
}
.app-bar-top ul li a {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.app-bar-top ul li a:hover {
  color: #3175ba;
}

/* Extra Small Media Query Start Here */
@media (min-width: 350px) and (max-width: 600px) {
  .v-navigation-drawer {
    width: 100% !important;
    height: auto !important;
  }
  .container {
    max-width: 100%;
  }
  .drawer-btn {
    margin-right: 10px;
    display: inline-block;
  }
  .v-navigation-drawer--open {
    top: 0 !important;
    bottom: auto !important;
  }
  .app-bar-top ul {
    display: none !important;
  }
  .app-bar-top:deep(.v-toolbar__content) {
    padding-left: 0;
    padding-right: 0;
  }
  .logo {
    width: 130px;
  }
  .small-size-mobile {
    font-size: 13px;
    padding: 0 10px;
  }
  .top-bar-header {
    padding: 7px 0 6px;
  }
  .top-bar-header span {
    font-size: 13px;
    margin-left: 10px;
  }
}
/* Extra Small Media Query End Here */

/* Small Media Query Start Here */
@media (min-width: 601px) and (max-width: 960px) {
  .v-navigation-drawer {
    width: 100% !important;
    height: auto !important;
  }
  .container {
    max-width: 100%;
  }
  .drawer-btn {
    margin-right: 15px;
    display: inline-block;
  }
  .v-navigation-drawer--open {
    top: 0 !important;
    bottom: auto !important;
  }
  .app-bar-top ul {
    display: none !important;
  }
}
/* Small Media Query End Here */

/* Extra Small Media Query Start Here */
@media (min-width: 350px) and (max-width: 473px) {
  .app-bar-top {
    top: 55px;
  }
}
/* Extra Small Media Query End Here */

</style>