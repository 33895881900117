<template>
  <div class="content-wrapper">
    <TopBar />
    <div class="products-banner sem-product-banner">
      <v-container class="pt-0 pb-0 d-flex justify-end">
        <div class="alignment-box">
          <h1>SMART EXAM MONITOR</h1>
          <p>
            A comprehensive and powerful tool for educational
            institutions to conduct secure and effective remote
            exams and quizzes.
          </p>
        </div>
      </v-container>
    </div>
    <!--Dashboard Banner End Here-->
    <div class="exam-feedback">
      <v-container class="pt-0 pb-0">
        <h2>SUPERCHARGE YOUR EXAMS WITH SEM'S STANDOUT FEATURES:</h2>
        <v-row gutters="12">
          <v-col cols="12" md="6" lg="6">
            <div class="verifies-identity">
              <div>
                <img src="images/edufide-new-design/check.svg" alt="">
              </div>
              <div class="ml-5">
                <h3>Confirms Identity</h3>
                <p>
                  Verifies student's identity using campus student
                  ID through computer vision.
                </p>
              </div>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" md="6" lg="6">
            <div class="verifies-identity">
              <div>
                <img src="images/edufide-new-design/check.svg" alt="">
              </div>
              <div class="ml-5">
                <h3>Confirms Student</h3>
                <p>
                  Utilizes facial recognition to match student's face
                  with the provided ID, further ensuring confirmation.
                </p>
              </div>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" md="6" lg="6">
            <div class="verifies-identity">
              <div>
                <img src="images/edufide-new-design/check.svg" alt="">
              </div>
              <div class="ml-5">
                <h3>Integrated and Secure</h3>
                <p>
                  Integrates with your Learning Management System
                  (LMS) via Learning Tools Interoperability (LTI) without
                  the need for additional plugins, ensuring a secure
                  connection.
                </p>
              </div>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" md="6" lg="6">
            <div class="verifies-identity">
              <div>
                <img src="images/edufide-new-design/check.svg" alt="">
              </div>
              <div class="ml-5">
                <h3>No Downloads</h3>
                <p>
                  Use SEM in a Chrome browser without the need for
                  special software downloads, making it more accessible for students.
                </p>
              </div>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" md="6" lg="6">
            <div class="verifies-identity">
              <div>
                <img src="images/edufide-new-design/check.svg" alt="">
              </div>
              <div class="ml-5">
                <h3>Live Monitoring</h3>
                <p>
                  Allows you to monitor exams in real-time and can
                  detect events like cell phone use and tags them for review.
                </p>
              </div>
            </div>
          </v-col>
          <!--///-->
          <v-col cols="12" md="6" lg="6">
            <div class="verifies-identity">
              <div>
                <img src="images/edufide-new-design/check.svg" alt="">
              </div>
              <div class="ml-5">
                <h3>AI-Based</h3>
                <p>
                  Employs various AI and machine learning
                  technologies to monitor video, audio, and screen
                  activity and automatically tags events for review
                  based on AI analysis.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Dashboard Banner End Here-->
    <div class="how-two-work">
      <v-container class="pt-0 pb-0">
        <h2>HOW SEM WORKS:</h2>
        <p>
          Seamless steps from Setup to Real-time Monitoring and Detailed Reporting..
        </p>
      </v-container>
      <div class="performance-analysis">
        <v-container class="pt-0 pb-0">
          <v-row gutters="12">
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card min-h-310">
                <span class="card-serial">1.</span>
                <img src="images/edufide-new-design/setup.svg" alt="">
                <h3>Setup</h3>
                <p class="mb-0">
                  Integrate SEM by adding it as an LTI tool to your LMS or course,
                  and configure multiple exam sessions to meet your specific
                  monitoring requirements.
                </p>
              </div>
            </v-col>
            <!--//-->
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card min-h-310">
                <span class="card-serial red-color">2.</span>
                <img class="max-w-80" src="images/edufide-new-design/delivery.svg" alt="">
                <h3>Delivery</h3>
                <p class="mb-0">
                  Students take exams using cutting-edge browser-based
                  technology, eliminating the need for complex software
                  installations.
                </p>
              </div>
            </v-col>
            <!--//-->
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card min-h-310">
                <span class="card-serial green-color">3.</span>
                <img class="max-w-80" src="images/edufide-new-design/monitor.svg" alt="">
                <h3>Monitoring</h3>
                <p class="mb-0">
                  Monitor exams in real-time with video and desktop activity of
                  students. Any flagged events are highlighted for immediate
                  attention.
                </p>
              </div>
            </v-col>
            <!--//-->
            <v-col cols="12" sm="6" md="6" lg="3">
              <div class="card min-h-310">
                <span class="card-serial yellow-color">4.</span>
                <img class="max-w-80" src="images/edufide-new-design/report.svg" alt="">
                <h3>Reporting</h3>
                <p class="mb-0">
                  SEM provides a comprehensive monitoring data for each exam,
                  includeing ID check, pre-exam video of student's environment,
                  and AI-analyzed video of the exam itself.
                </p>
              </div>
            </v-col>
            <!--//-->
          </v-row>
        </v-container>
      </div>
    </div>
    <!--How Two Work End Here-->
    <div class="grading-feedback-wrapper">
      <v-container class="pt-0 pb-0">
        <v-row gutters="12">
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="name-department-institution">
              <div class="image-circle"></div>
              <p class="mb-5">
                “Lorem ipsum dolor sit amet, consec- tetuer adipiscing elit, sed diam nonum-
                my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                wisi enim ad minim veniam”
              </p>
              <h3>Name <br> Department</h3>
              <h4>Institution Name</h4>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="name-department-institution">
              <div class="image-circle"></div>
              <p class="mb-5">
                “Lorem ipsum dolor sit amet, consec- tetuer adipiscing elit, sed diam nonum-
                my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                wisi enim ad minim veniam”
              </p>
              <h3>Name <br> Department</h3>
              <h4>Institution Name</h4>
            </div>
          </v-col>
          <!--//-->
          <v-col cols="12" sm="6" md="6" lg="4">
            <div class="name-department-institution">
              <div class="image-circle"></div>
              <p class="mb-5">
                “Lorem ipsum dolor sit amet, consec- tetuer adipiscing elit, sed diam nonum-
                my nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
                wisi enim ad minim veniam”
              </p>
              <h3>Name <br> Department</h3>
              <h4>Institution Name</h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!--Grading Feedback Wrapper End Here-->
    <FooterView />
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  components: {
    TopBar,
    FooterView,
  },
  name: "SemProduct",
  data: () => ({

  }),
};
</script>

<style scoped></style>