import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Assign2 from '../views/Assign2.vue'
import Stream2 from '../views/Stream2.vue'
import AboutE from '../views/AboutE.vue'
import Values from '../views/Values.vue'
import SEM from '../views/SEM.vue'
import SEM2 from '../views/SEM2.vue'
import Privacy from '../views/Privacy.vue'
import Contact from '../views/Contact.vue'
import Demo from '../views/Demo.vue'
import Free from '../views/Free.vue'
import FreeRegister from '../views/FreeRegister.vue'
import UKIEMoot from '../views/UKIEMoot.vue'
import LandingPage from '../views/LandingPage'
import KnowledgeBase from '../views/KnowledgeBase'
import ManageAssign from '../views/ManageAssign'
import CreatePaper from '../views/CreatePaper'
import ContentConversion from '../views/ContentConversion.vue'
import CreateAssignment from '../views/CreateAssignment'
import SubmitAssign from '../views/SubmitAssign'
import CreateCourse from '../views/CreateCourse'
import MarkAssign from '../views/MarkAssign'
import ManagingAssignments from '../views/ManagingAssignments.vue'
import MarkingSubmissions from '../views/MarkingSubmissions.vue'
import DocsCreatePrintExam from '../views/DocsCreatePrintExam.vue'
import DocsCreateAssignment from '../views/DocsCreateAssignment.vue'
import DocsSubmitAssignNew from '../views/DocsSubmitAssignNew'
import DocsTemplate from '../views/DocsTemplate'
import DocsReturnAssessments from '../views/DocsReturnAssessments'
import Accessibility from '../views/Accessibility.vue'
import DocsIntegrateCanvas from '../views/DocsIntegrateCanvas.vue'
import DocsIntegrateMoodle from '../views/DocsIntegrateMoodle.vue'
import DocsAddMCQ from '../views/DocsAddMCQ.vue'
import DocsManageStudents from '../views/DocsManageStudents.vue'
import DocsReplaceBubblesheets from '../views/DocsReplaceBubblesheets.vue'
import Dashboard from '../views/Dashboard.vue'
import Products from '../views/Products.vue'
import SemProduct from '../views/SemProduct.vue'
import Support from '../views/Support.vue'
import HelpCenterDocumentation from '../views/HelpCenterDocumentation.vue'
import LayoutWithHeaderFooter from "@/layout/LayoutWithHeaderFooter.vue";
import SimpleLayout from "@/layout/SimpleLayout.vue";
import DocsSEM2TakeExam from "@/views/DocsSEM2TakeExam.vue";
import DocsInstructorSEM2 from "@/views/DocsInstructorSEM2.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LayoutWithHeaderFooter',
    component: LayoutWithHeaderFooter,
    children : [
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: '/about',
        name: 'about',
        component: AboutE
      },
      {
        path: '/assign2',
        name: 'assign2',
        component: Assign2
      }
      ,
      {
        path: '/stream2',
        name: 'stream2',
        component: Stream2
      },
      {
        path: '/values',
        name: 'values',
        component: Values
      },
      {
        path: '/smartexammonitor',
        name: 'smartexammonitor',
        component: SEM
      },
      {
        path: '/sem2',
        name: 'sem2',
        component: SEM2
      },
      {
        path: '/docs-example',
        name: 'example',
        component: LandingPage
      },
      {
        path: '/docs-create-assignment',
        name: 'create-assignment',
        component: CreateAssignment
      },
      {
        path: '/docs-manage-assignment',
        name: 'docs-manage-assignment',
        component: ManageAssign
      },
      {
        path: '/docs-submit-assign',
        name: 'submit-assign',
        component: SubmitAssign
      },
      {
        path: '/docs-create-paper',
        name: 'create-paper',
        component: CreatePaper
      },
      {
        path: '/docs-mark-assignment',
        name: 'mark-assignment',
        component: MarkAssign
      },
      {
        path: '/docs-create-course',
        name: 'create-course',
        component: CreateCourse
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
      },
      {
        path: '/contact',
        name: 'contact',
        component: Contact
      },
      {
        path: '/demo',
        name: 'demo',
        component: Demo
      },
      {
        path: '/free',
        name: 'free',
        component: Free
      },
      {
        path: '/freeregister',
        name: 'freeregister',
        component: FreeRegister
      },
      {
        path: '/ukiemoot',
        name: 'ukiemoot',
        component: UKIEMoot
      },
      {
        path: '/knowledgebase',
        name: 'knowledgebase',
        component: KnowledgeBase
      },
      {
        path: '/docs-manage-assignment',
        name: 'docs-manage-assignment',
        component: ManageAssign
      },
      {
        path: '/docs-submit-assign',
        name: 'submit-assign',
        component: SubmitAssign
      },
      {
        path: '/docs-create-paper',
        name: 'create-paper',
        component: CreatePaper
      },
      {
        path: '/docs-mark-assignment',
        name: 'mark-assignment',
        component: MarkAssign
      },

      {
        path: '/docs-create-course',
        name: 'create-course',
        component: CreateCourse
      },

      {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
      },
      {
        path: '/contact',
        name: 'contact',
        component: Contact
      },
      {
        path: '/demo',
        name: 'demo',
        component: Demo
      },
      {
        path: '/free',
        name: 'free',
        component: Free
      },
      {
        path: '/freeregister',
        name: 'freeregister',
        component: FreeRegister
      },
      {
        path: '/ukiemoot',
        name: 'ukiemoot',
        component: UKIEMoot
      },
      {
        path: '/knowledgebase',
        name: 'knowledgebase',
        component: KnowledgeBase
      },
      {
        path: '/docs-managing-assignments',
        name: 'managingassignments',
        component: ManagingAssignments
      },
      {
        path: '/docs-marking-submissions',
        name: 'markingsubmissions',
        component: MarkingSubmissions
      },
      {
        path: '/docs-create-print-exam',
        name: 'docscreateprintexam',
        component: DocsCreatePrintExam
      },
      {
        path: '/docs-create-assignment',
        name: 'docscreateassignment',
        component: DocsCreateAssignment
      },
      {
        path: '/docs-submit-assignment',
        name: 'submit-assign-new',
        component: DocsSubmitAssignNew
      },
      {
        path: '/docs-add-mcq',
        name: 'docs-add-mcq',
        component: DocsAddMCQ
      },
      {
        path: '/docs-template',
        name: 'docs-template',
        component: DocsTemplate
      },
      {
        path: '/docs-return-assessments',
        name: 'return-assessments',
        component: DocsReturnAssessments
      },
      {
        path: '/policy-accessibility',
        name: 'policy-accessibility',
        component: Accessibility
      },
      {
        path: '/docs-integrate-canvas',
        name: 'docs-integrate-canvas',
        component: DocsIntegrateCanvas
      },
      {
        path: '/docs-integrate-moodle',
        name: 'docs-integrate-moodle',
        component: DocsIntegrateMoodle
      },

      {
        path: '/docs-manage-students',
        name: 'docs-manage-students',
        component: DocsManageStudents
      },
      {
        path: '/docs-replace-bubblesheets',
        name: 'docs-replace-bubblesheets',
        component: DocsReplaceBubblesheets
      },
      {
        path: '/docs-take-exam-semv2',
        name: 'docs-take-exam-semv2',
        component: DocsSEM2TakeExam
      },
      {
        path: '/docs-instructor-semv2',
        name: 'docs-instructor-semv2',
        component: DocsInstructorSEM2
      }
      // {
      // path: '/docs-create-assignment',
      //   name: 'create-assignment',
      //   component: CreateAssignment
      // },
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/sem-product',
    name: 'SemProduct',
    component: SemProduct
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/help-center-documentation',
    name: 'HelpCenterDocumentation',
    component: HelpCenterDocumentation
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
