<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "SimpleLayout",
};
</script>

<style scoped>

</style>
