<template>

    <section class="submit-assign-section">
        <div class="container">
            <div class="content-area">
                <h1> Smart Exam Monitor V2 - For Instructors </h1>
                <p>
                    This guide provides instructions on how to manage exam sessions in Smart Exam Monitor V2.
                </p>

                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <!-- introduction -->
                        <li>
                            <a href="#introduction">
                                <span>1</span>
                                Intro to Smart Exam Monitor V2
                            </a>
                        </li>
                        <li>
                            <a href="#createExamSession">
                                <span>2</span>
                                Create a New Exam Session 
                            </a>
                        </li>
                        <!-- monitoring exam session -->
                        <li>
                            <a href="#monitorExamSession">
                                <span>3</span>
                                Monitor an Exam Session
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // -->
                <div class="pt-55" id="introduction">
                    <h2>Intro to Smart Exam Monitor V2</h2>
                    <p>
                        Smart Exam Monitor V2 is a secure exam proctoring solution that allows instructors to monitor students during exams.
                         <!--
                            Features: recording of screen and webcam. Recording is stored locally and uploaded asynchronously to the server.
                            This ensures that the recording is not lost even if the internet connection is lost.
                            Application runs in a PWA web app and can be installed on the desktop.
                          -->
                        <br>
                        <strong>Features:</strong>
                        <br>
                        <ul>
                            <li>Recording of screen and webcam.</li>
                            <li>Recordings are stored locally and uploaded asynchronously to the server, mitigating network failures.</li>
                            <li>Application runs in a PWA web app and can be installed on personal computers.</li>
                            <li>Optionally allows recording with a secondary camera, such as a cellphone.</li>
                        </ul>
                        <br>
                        <strong>How it works:</strong>
                        <br>
                        Students access the exam session from the appropriate course in their LMS and are required to install the SEM V2 application. 
                        Additionally, students are required to validate their device after installing the application and before starting the exam.

                        <br>
                        <br>
                        <!-- after the exam instructor can review videos and flagged events -->
                        After the exam, instructors can review the videos and flagged events.
                        
                    </p>
                </div>
                <div class="pt-55" id="createExamSession">
                    <h2>Create a New Exam Session</h2>
                    <p>
                        To create a new exam session, access SEM V2 from the appropriate course in your LMS.
                        <br>
                        <strong>
                            Click on the "Create Session" button to create a new exam session.
                        </strong>
                        <br>
                        The following information is required to create a new exam session:
                        <br>
                        <br>
                        <ul>
                            <li> <strong>Session Title:</strong> A title for the exam session.</li>
                            <!-- description -->
                            <li> <strong>Description:</strong> A description for the exam session.</li>
                            <!-- start date -->
                            <li> <strong>Start Date:</strong> The date when the exam session will start.</li>
                            <!-- start time -->
                            <li> <strong>Start Time:</strong> The time when the exam session will start.</li>
                            <li> <strong>Duration:</strong> The duration of the exam session.</li>
                            <!-- type -->
                            <li> <strong>Type:</strong> The type of exam session. The options are: External Quiz (e.g., on your LMS), SEM2 Quiz.</li>
                            <!-- exam url -->
                            <li> <strong>Exam URL:</strong> The URL of the exam (only required for External Quiz type).</li>
                            <!-- exam password -->
                            <li> <strong>Exam Password:</strong> The password of the exam (only required for External Quiz type).</li>
                            <!-- Exam instructions -->
                            <li> <strong>Exam Instructions:</strong> The instructions for the exam. These will be displayed to students precisely at the start time.</li>
                            <!-- General Instructions -->
                            <li> <strong>General Instructions:</strong> The general instructions for the exam. These will be displayed to students as soon as they complete the sign-in process and before the exam starts.</li>
                            <!-- Monitoring -->
                            <li> <strong>Monitoring:</strong> The monitoring level for the exam. The options are:
                                <br>
                                Camera <i>(currently enabled)</i> <br>
                                Screen <i>(currently enabled)</i> <br>
                                Audio <i>(currently enabled)</i> <br>
                                Secondary Camera <i>(currently disabled)</i> <br>
                            </li>
                        </ul>
                    </p>
                </div>

                <div class="pt-55" id="monitorExamSession">
                    <h2>Monitor an Exam Session</h2>
                    <p>
                        To monitor an exam session, access SEM V2 from the appropriate course in your LMS.
                        <br>
                        <strong>
                            Click on the monitoring button to access the monitoring interface.
                        </strong>
                        <br>

                        <img src="images/docs-instructor-semv2/monitoringbutton.png" alt="">

                        <br>
                        On the monitoring interface, you can view sessions by different students.
                        <!-- insessionmonitoring.png -->
                        <br>
                        <img src="images/docs-instructor-semv2/insessionmonitoring.png" alt="">
                        <br>
                        Clicking on a session will open the monitoring interface for that session. At the top of the monitoring interface, 
                        you can see the student's name, student ID, their picture, and video recordings.
                        <!-- sessiondetails.png -->
                        <br>
                        <img src="images/docs-instructor-semv2/sessiondetails.png" alt="">
                        <br>
                        Below the recordings, a timeline is displayed. The timeline shows the events flagged by the system.
                        Clicking on the timeline will bring you to the relevant time in the recording.
                        <!-- further down there is events are shown -->
                        <br>
                        <img src="images/docs-instructor-semv2/events.png" alt="">
                    </p>
                </div>

            </div>
        </div>
    </section>

</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.ytcontainer {
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: 370px;
    padding-bottom: 0px;
}
.ytvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 360px;
}
.flex-box {
    display: flex;
    padding-top: 30px;
}
.flex-box span {
    margin-right: 5px;
    line-height: 28px;
}
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .container {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #ff0000;
}
.submit-assign-section .content-area .card {
    max-width: 300px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    margin-bottom: 0;
    line-height: 28px;
}
.submit-assign-section .content-area img {
    margin-top: 5px;
    margin-left: 20px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .container {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .container {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .container {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
}
</style>
