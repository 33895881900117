<template>
  <div class="content-wrapper">
    <TopBar />
    <div class="help-documentation-wrapper">
      <v-container class="pt-0 pb-0">
        <div class="card">
          <h2>Getting Started for Instructors</h2>
          <p>
            Details to help you get started on making the <br>
            most of Assign2’s features
          </p>
          <hr>
          <h3>Contents</h3>
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>Creating an assessment</v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    <router-link to=""><span>a.</span> Paper-based exams</router-link>
                    <ul>
                      <li>
                        <router-link to=""><span>1.</span> Creating an exam</router-link>
                      </li>
                      <li>
                        <router-link to=""><span>2.</span> Printing exams</router-link>
                      </li>
                      <li>
                        <router-link to=""><span>3.</span> Scanning & Uploading</router-link>
                      </li>
                      <li>
                        <router-link to=""><span>4.</span> Replacing bubblesheets</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <!--//-->
                <ul>
                  <li>
                    <router-link to=""><span>b.</span> Assignments</router-link>
                    <ul>
                      <li>
                        <router-link to=""><span>1.</span>  Creating an assignment</router-link>
                      </li>
                      <li>
                        <router-link to=""><span>2.</span> Setting due dates and penalties</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--//-->
            <v-expansion-panel>
              <v-expansion-panel-header>Grading</v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="pl-30">
                  <li>
                    <router-link to=""><span>1.</span> Grading submissions</router-link>
                  </li>
                  <li>
                    <router-link to=""><span>2.</span> Grading markup tools</router-link>
                  </li>
                  <li>
                    <router-link to=""><span>3.</span> Marking workflow tools</router-link>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--//-->
            <v-expansion-panel>
              <v-expansion-panel-header>Returning graded assessments</v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="pl-30">
                  <li>
                    <router-link to=""><span>1.</span>  Returning Assessments to students</router-link>
                  </li>
                  <li>
                    <router-link to=""><span>2.</span> Feedback visibility</router-link>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--//-->
            <v-expansion-panel>
              <v-expansion-panel-header>Managing students</v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    <router-link to=""><span>a.</span> Paper-based exams</router-link>
                  </li>
                  <li>
                    <router-link to=""><span>b.</span> Assignments</router-link>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--//-->
            <v-expansion-panel>
              <v-expansion-panel-header>Troubleshooting</v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="pl-30">
                  <li>
                    <router-link to=""><span>1.</span> Dashboard Overview</router-link>
                  </li>
                  <li>
                    <router-link to=""><span>2.</span> Partially scanned exams</router-link>
                  </li>
                  <li>
                    <router-link to=""><span>3.</span> Unassigned exams</router-link>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--///-->
          <h3>Creating an assessment</h3>
          <p>
            Assign2 supports two types of assessments: In-person paper-based exams and
            Assignments. It streamlines grading, saving time and enhancing in-person
            assessments.
          </p>
          <h4><span>a.</span> In-person paper-based exams</h4>
          <div class="ytcontainer">
            <iframe class="ytvideo" width="640" height="360" src="https://www.youtube.com/embed/bTRsnHPdmmk" title="Creating an in-person exam on Assign2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <p class="font-weight-500">
            The starting point for this type of assessment is to have the questions
            prepared in an existing PDF template file.
          </p>
          <p>
            <span>1.</span>
            With that in hand, instructors should begin by adding an Assign2 tool to
            their course, ensuring that the 'Grade' points selected matches what the
            eventual points total will be for the assessment. Once the tool has been
            added, click the tool link in the course and select In Person Exam.
          </p>
          <div class="d-flex mlb-25">
            <img src="images/edufide-new-design/assessment-help-one.png" alt="">
          </div>
          <p>
            <span>2.</span>
            Next, upload the PDF version of your exam file by drag-dropping or
            browsing. This file should contain all the questions and space for students
            to complete their answers.
          </p>
          <div class="d-flex mlb-25">
            <img src="images/edufide-new-design/assessment-help-two.png" alt="">
          </div>
          <p>
            <span>3.</span>
            Once uploaded, the PDF is opened in a preview mode that first shows the
            cover page for the eventual student copies.
          </p>
          <div class="d-flex mlb-25">
            <img src="images/edufide-new-design/assessment-help-three.png" alt="">
          </div>
          <p>
            <span>4.</span>
            Scrolling down, instructors will then create the question placeholders to
            be linked to the Assign2 grading interface. To add a question, click Add
            Question then drag-and-drop the blue question marker over top of the
            question in the underlying template, then enter the point-value for the
            question to match the assessment item.
          </p>
          <div class="d-flex mlb-25">
            <img src="images/edufide-new-design/assessment-help-four.png" alt="">
          </div>
          <p>
            <span>5.</span>
            Then repeat this process for every question in the assessment and once
            complete, scroll down and click Save.
          </p>
          <div class="d-flex mlb-25">
            <img src="images/edufide-new-design/assessment-help-five.png" alt="">
          </div>
        </div>
      </v-container>
    </div>
    <!--Dashboard Banner End Here-->
    <FooterView />
  </div>
</template>

<script>
// import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  components: {
    TopBar,
    FooterView,
  },
  name: "Support",
  data: () => ({

  }),
};
</script>

<style scoped></style>