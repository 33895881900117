<template>
  <footer class="footer-wrapper">
    <v-container class="pt-0 pb-0">
      <v-row gutters="12">
        <v-col cols="12" lg="12" class="d-flex justify-start">
          <a href="" class="d-inline-block">
            <img src="images/edufide-new-design/Footer-logo.png" alt="">
          </a>
        </v-col>
      </v-row>
      <!--//-->
      <v-row gutters="12" class="pt-5">
        <v-col cols="12" md="4" lg="4">
          <p>@ 2023 Edufide Inc. All rights reserved.</p>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <ul>
            <li>
              <router-link to="">Privacy Policy</router-link>
            </li>
            <li>
              <router-link to="">Accessibility Policy</router-link>
            </li>
            <li>
              <router-link to="">Terms and Conditions</router-link>
            </li>
            <li>
              <router-link to="">Contact</router-link>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped>

.footer-wrapper {
  padding: 50px 0;
  background: #2F4052;
}
.footer-wrapper img {
  width: 150px;
}
.footer-wrapper p {
  color: #d9d9d9;
  text-align: left;
}
.footer-wrapper ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-wrapper ul li {
  margin-left: 35px;
}
.footer-wrapper ul li a {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 400;
}

@media (min-width: 350px) and (max-width: 600px) {
  .footer-wrapper {
    padding: 20px 15px;
  }
  .v-application .footer-wrapper .pt-5 {
    padding-top: 0 !important;
  }
  .footer-wrapper ul {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-wrapper ul li {
    margin-left: 0;
    margin-bottom: 15px;
  }
  .footer-wrapper ul li:last-of-type {
    margin-bottom: 0;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .footer-wrapper ul {
    padding-left: 0;
    justify-content: flex-start;
  }
  .footer-wrapper ul li {
    margin: 0 15px 0 0;
  }
}

</style>