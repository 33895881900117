import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-bar-header"},[_c(VContainer,{staticClass:"d-flex align-center justify-center pt-0 pb-0"},[_c('p',[_vm._v(" Interested in trying Assign2 for free? "),_c('a',{attrs:{"href":"https://edufide.com/demo"}},[_vm._v("Click here to learn more.")])])])],1),_c(VAppBar,{staticClass:"app-bar-top",attrs:{"prominent":""}},[_c(VContainer,{staticClass:"d-flex align-center justify-space-between pt-0 pb-0"},[_c('div',{staticClass:"d-flex align-center"},[_c(VAppBarNavIcon,{staticClass:"drawer-btn",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('img',{staticClass:"logo",attrs:{"src":"images/edufide_logo_linear.svg","alt":"Logo"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('ul',{staticClass:"d-flex align-center"},[_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("Products")])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("About")])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("Support")])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_vm._v("Contact")])],1)]),_c(VBtn,{staticClass:"small-size-mobile",attrs:{"color":"primary"}},[_vm._v("Get a Trial")])],1)])],1),_c(VNavigationDrawer,{attrs:{"absolute":"","top":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Products")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("About")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("Support")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("Contact")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }